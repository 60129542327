var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-3" }, [
    _vm.edit && !_vm.comment.status
      ? _c(
          "div",
          [
            _c("post-comment", {
              attrs: { comment: _vm.comment, "edit-mode": true },
              on: {
                close: function ($event) {
                  _vm.edit = false
                },
              },
            }),
          ],
          1
        )
      : _c("div", [
          _c("div", { class: { "opacity-50": _vm.editComment } }, [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                { staticClass: "flex flex-col items-center justify-between" },
                [
                  _c("user-avatar", {
                    attrs: {
                      avatar: _vm.comment.user.avatarUrl || "",
                      size: 2,
                    },
                  }),
                  _vm._t("icon"),
                ],
                2
              ),
              _c("div", { staticClass: "ml-3 w-full" }, [
                _c(
                  "div",
                  { staticClass: "flex flex-col justify-between font-bold" },
                  [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.comment.user.first_name) +
                          " " +
                          _vm._s(_vm.comment.user.last_name) +
                          " "
                      ),
                      _c("span", { staticClass: "font-medium text-gray-500" }, [
                        _vm._v(" - " + _vm._s(_vm.formattedDate)),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex items-center" },
                      [
                        _c("span", { staticClass: "mr-2 font-medium" }, [
                          _vm._v(
                            _vm._s(_vm.comment.status ? "Sent" : "Private")
                          ),
                        ]),
                        _vm.comment.status
                          ? _c("fv-icon", {
                              staticClass: "text-fv-green",
                              attrs: { icon: "check-mark" },
                            })
                          : _vm._e(),
                        !_vm.comment.status && !_vm.selectMode
                          ? _c(
                              "text-button",
                              {
                                staticClass:
                                  "flex h-6 w-6 items-center justify-center rounded-full hover:bg-opacity-50",
                                class: _vm.editComment
                                  ? "cursor-default bg-opacity-50"
                                  : "cursor-pointer",
                                on: { click: _vm.setEdit },
                              },
                              [
                                _c("fv-icon", {
                                  staticClass: "text-xs",
                                  attrs: { icon: "edit" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", [
                  _c("p", { staticClass: "comment pt-2" }, [
                    _vm._v(" " + _vm._s(_vm.comment.comment) + " "),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }